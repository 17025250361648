export default function X() {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="19" cy="19" r="19" fill="#404040" />
      <g clipPath="url(#clip0_535_701)">
        <rect
          width="16"
          height="18"
          transform="translate(11 10)"
          fill="#404040"
        />
        <path
          d="M20.5222 17.6179L26.4785 10H25.0671L19.8952 16.6145L15.7644 10H11L17.2466 20.0023L11 27.9908H12.4115L17.8732 21.0056L22.2356 27.9908H27L20.5218 17.6179H20.5222ZM18.5889 20.0904L17.956 19.0944L12.9201 11.1691H15.0882L19.1522 17.5651L19.7851 18.5611L25.0677 26.8748H22.8997L18.5889 20.0908V20.0904Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_535_701">
          <rect
            width="16"
            height="18"
            fill="white"
            transform="translate(11 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
