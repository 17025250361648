export default function Introduction() {
  return (
    <div className="grid justify-items-center py-16 font-zenOldMincho">
      <h3 className="mb-12 text-2xl mobile:mb-8 mobile:text-lg">
        「あ、いいな。」って思える映像
      </h3>
      <div className="text-center text-lg leading-[42px] mobile:text-base mobile:leading-8">
        <p className="mb-10 mobile:mb-8">
          大切にしてる事は、理屈よりも感情の動き。
          <br />
          「あ、いいな。」って思える感情を引き出すことで、
          <br />
          潜在的な意識に残る
          <br />
          そんな映像を目指しています。
        </p>
        <p>感情を動かせ</p>
      </div>
    </div>
  );
}
