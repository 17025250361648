export default function KeyboardArrowRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48px"
      viewBox="0 -960 960 960"
      width="48px"
      fill="#e8eaed"
    >
      <path d="M508-481 343-647q-14-13-13.5-31.5T344-711q12-13 31.5-13t32.5 13l197 197q6 6 10.5 15t4.5 18q0 9-4.5 18T605-448L407-250q-13 13-32 12.5T344-251q-14-14-14-33t14-32l164-165Z" />
    </svg>
  );
}
