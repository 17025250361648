import planLight from "../assets/planLight.jpg";
import planLightM from "../assets/planLightM.jpg";
import planPremium from "../assets/planPremium.jpg";
import planPremiumM from "../assets/planPremiumM.jpg";
import planStandard from "../assets/planStandard.jpg";
import planStandardM from "../assets/planStandardM.jpg";
import Timeline from "../utils/diagrams/Timeline";

export default function Service() {
  const textOverImageTemplate = () => {
    const imageItems = [
      {
        title: "ライト",
        content: "SNSリール、記録ムービー、HPループ映像など向け",
        price: "参考予算：数万円～",
        img: planLight,
        imgMobile: planLightM,
        alt: "Plan light",
      },
      {
        title: "スタンダード",
        content: "PR、PV、MV、採用、インタビューなど向け",
        price: "参考予算：20万円～",
        img: planStandard,
        imgMobile: planStandardM,
        alt: "Plan standard",
      },
      {
        title: "プレミアム",
        content:
          "WebCM、MV、ブランディング、ドキュメンタリー、ショートドラマなど向け",
        price: "参考予算：50万円～",
        img: planPremium,
        imgMobile: planPremiumM,
        alt: "Plan premium",
      },
    ];
    return imageItems.map((imageItem, index) => (
      <a
        key={index}
        className="group/service relative h-[458px] w-[331px] overflow-hidden object-cover shadow-base hover:cursor-pointer mobile:h-[190px] mobile:w-[351px]"
        href="/#contact"
      >
        <div className="group-hover/service:transition-after transition-before relative grid">
          <img
            src={imageItem.img}
            alt={imageItem.alt}
            className="mobile:hidden"
          />
          <img
            src={imageItem.imgMobile}
            alt={imageItem.alt}
            className="hidden mobile:block"
          />
        </div>
        <div className="absolute inset-x-0 top-0 mx-auto h-full w-[303px] font-inter mobile:mt-0">
          <div className="bottom-0 grid h-full content-between">
            <div>
              <h3 className="mb-2 mt-5 text-lg mobile:my-2">
                {imageItem.title}
              </h3>
              <p className="text-base leading-7">{imageItem.content}</p>
            </div>
            <h3 className="my-1 mt-72 text-base leading-7 mobile:mt-14">
              {imageItem.price}
            </h3>
          </div>
        </div>
      </a>
    ));
  };

  const timelineTemplate = () => {
    const timelineText = [
      {
        step: 1,
        title: "お問い合わせ・ヒアリング",
        content:
          "制作目的、ターゲット、商品、参考イメージ、などご依頼内容を詳しくお伺い致します。",
      },
      {
        step: 2,
        title: "お見積・ご依頼",
        content:
          "ヒアリングさせて頂いた内容をもとに最適な制作プランをご提案させて頂きます。",
      },
      {
        step: 3,
        title: "制作企画打ち合わせ",
        content:
          "ご依頼頂いた際には詳細な制作企画をお打ち合わせするため何度かお時間頂く場合がございます。",
      },
      {
        step: 4,
        title: "撮影",
        content:
          "撮影当日までにロケハン、機材手配など撮影準備を行います。撮影当日は担当者様にお立合い頂く場合がございます。",
      },
      {
        step: 5,
        title: "編集",
        content:
          "制作企画で決まった内容をもとに編集作業を行います。編集には時間を要するため完成までしばらくお待ち頂きます。",
      },
      {
        step: 6,
        title: "検収・納品",
        content:
          "編集が仕上がりましたら映像の確認をして頂きます。お客様で検収を行って頂き問題が無ければ完成データを納品させて頂きます。",
      },
    ];
    return (
      // 1367px はtimelineのwidth
      <div className="bottom-0 mx-2 grid max-w-[1367px] grid-flow-col justify-center justify-items-center gap-8 pb-36 mobile:ml-2 mobile:mr-[10%] mobile:h-[650px] mobile:grid-flow-row mobile:gap-0">
        {timelineText.map((text, index) => (
          <div
            key={index}
            className="mt-12 grid h-[81.5px] gap-8 font-inter mobile:mt-8 mobile:justify-items-start mobile:gap-2 mobile:justify-self-start"
          >
            <div className="grid grid-flow-col gap-2">
              <h3 className="justify-self-end text-base">{text.step} .</h3>
              <h3 className="justify-self-start text-base">{text.title}</h3>
            </div>
            <p className="text-base leading-7 mobile:text-start mobile:text-sm mobile:leading-5">
              {text.content}
            </p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="text-light -mx-1 grid justify-items-center bg-[#252525] py-16 text-center font-inter">
      <div className="mx-1">
        <h3 className="mb-6 text-2xl mobile:mb-8">Service</h3>
        <h3 className="mb-16 text-base leading-8 mobile:text-sm mobile:leading-5">
          お客様の目的に合わせて
          <br />
          フルオーダーメイドで企画・撮影・編集を行います。
          <br />
          ご予算に合わせて柔軟にプランをご提案いたします。
        </h3>
        <div className="mb-16 grid grid-flow-col justify-center justify-items-center gap-8 mobile:grid-flow-row">
          {textOverImageTemplate()}
        </div>
        <div>
          <h3 className="mb-6 text-2xl mobile:mb-8">Flow</h3>
          <p className="mb-16 text-base leading-8 mobile:text-sm mobile:leading-5">
            お見積金額が気になるかと思いますが、
            <br />
            まずは制作目的やターゲットなど詳しくヒアリングさせて頂き
            <br />
            ヒアリング内容をもとにお見積りさせて頂きます。
          </p>
          <div className="mobile:mb-8 mobile:grid mobile:grid-flow-col">
            <Timeline />
            {timelineTemplate()}
          </div>
        </div>
      </div>
    </div>
  );
}
