import React from "react";

export default function VideoDetail(props) {
  const [width, setWidth] = React.useState(0);
  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setWidth(entry.contentRect.width);
      }
    });
    if (widthRef.current) {
      resizeObserver.observe(widthRef.current);
    }
    return () => resizeObserver.disconnect();
  }, [setWidth]);

  const widthRef = React.useRef(null);

  return (
    <>
      <div
        ref={widthRef}
        className="mb-6 flex size-full flex-col items-center px-3"
      >
        <div className="mb-6 text-center font-inter text-lg">
          {props.videoTitle}
        </div>
        <iframe
          className="aspect-video max-w-[1114px] shadow-base"
          width={width}
          src={`https://www.youtube.com/embed/${props.videoId}`}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
      <div className="-mx-1 bg-[#DDDDDD] py-8">
        <div className="grid justify-center">
          <div className="mx-4 grid max-w-[1114px] grid-cols-2 justify-items-center gap-4 mobile:grid-cols-1">
            {[...Array(4)].map((_, index) => (
              <img
                key={index}
                className="aspect-video w-[549px] object-cover shadow-base"
                src={`https://altput-video.com/myYoutubeVideos/videoLink${Number(props.videoNumber) + 1}/images/image (${index + 1}).jpg`}
                alt={props.videoTitle}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
