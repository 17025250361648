import { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import About from "./components/About";
import Contact from "./components/Contact";
import Introduction from "./components/Introduction";
import LayoutMargin from "./components/LayoutMargin";
import Menu from "./components/Menu";
import Service from "./components/Service";
import ThumbnailSlider from "./components/ThumbnailSlider";
import Top from "./components/Top";
import VideoDetail from "./components/VideoDetail";
import getVideoDetails from "./utils/api/getVideoDetails";

export default function App() {
  const [videoDetails, setVideoDetails] = useState();
  const [error, setError] = useState(null);
  useEffect(() => {
    getVideoDetails({ setVideoDetails, setError });
  }, [setVideoDetails, setError]);
  const homeComponent = <Home videoDetails={videoDetails} error={error} />;
  const isVideoExist = videoDetails
    ? videoDetails
        .map((videoDetail) => `/videos/${Object.keys(videoDetail)}`)
        .includes(window.location.pathname)
    : null;

  return (
    <div className="min-w-[375px] overflow-x-hidden">
      <LayoutMargin>
        <Menu />
        <Routes>
          <Route path="/" element={homeComponent} />
          {videoDetails &&
            videoDetails.map((videoDetail) => (
              <Route
                key={Object.keys(videoDetail)}
                path={`/videos/${Object.keys(videoDetail)}`}
                element={
                  <VideoDetail
                    videoNumber={videoDetails.indexOf(videoDetail)}
                    videoId={Object.keys(videoDetail)}
                    videoTitle={Object.values(videoDetail)}
                  />
                }
              />
            ))}
          {!isVideoExist && isVideoExist !== null && (
            <Route path="/*" element={<Navigate replace to="/" />} />
          )}
        </Routes>
      </LayoutMargin>
    </div>
  );
}

function Home(props) {
  return (
    <>
      <section>
        <Top />
        <ThumbnailSlider
          videoDetails={props.videoDetails}
          error={props.error}
        />
        <Introduction />
      </section>
      <section id="service">
        <Service />
      </section>
      <section id="about">
        <About />
      </section>
      <section id="contact">
        <Contact />
      </section>
    </>
  );
}
