export default function KeyboardArrowLeft() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48px"
      viewBox="0 -960 960 960"
      width="48px"
      fill="#e8eaed"
    >
      <path d="m428-481 166 166q13 13 12.5 31.48T593-251q-13 13-32.48 13-19.48 0-32.52-13L332-448q-6.91-5.71-10.95-14.71-4.05-9-4.05-18.29 0-8.57 4.05-17.82 4.04-9.25 10.95-15.18l197-198q13.04-13 32.52-13Q581-725 594-712q13 14.04 13 33.02Q607-660 594-647L428-481Z" />
    </svg>
  );
}
