export default function ArrowDropUp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill="#e8eaed"
    >
      <path d="M294-372q-14.9 0-24.45-10.46-9.55-10.47-9.55-23.09 0-5.45 11-24.45l173.33-173.3Q452-611 461.07-614q9.07-3 18.75-3t18.93 3q9.25 3 16.92 10.7L689-430q5 4.95 8 11.25t3 13.2q0 12.55-9.55 23.05T666-372H294Z" />
    </svg>
  );
}
