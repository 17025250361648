import profileReiji from "../assets/profileReiji.jpg";

export default function About() {
  return (
    <div className="-mx-1 grid justify-items-center bg-[#DDDDDD] pb-32 pt-16 text-center mobile:pb-16">
      <div className="mx-1 w-[1059px] mobile:w-full">
        <h3 className="mb-[88px] font-inter text-2xl mobile:mb-8">About</h3>
        <div className="flex gap-40 text-start mobile:flex-col-reverse mobile:gap-12 mobile:px-[10%]">
          <div className="mx-auto">
            <img
              src={profileReiji}
              alt="Profile Reiji"
              className="aspect-[3/4] w-[650px] object-cover"
            />
          </div>
          <div className="flex flex-col">
            <div className="grow mobile:mb-8">
              <h3 className="titillium-web-semibold mb-4 flex flex-wrap text-4xl leading-none tracking-wider mobile:text-[40px]">
                <span className="mr-6">REIJI</span>
                <span>MURAMATSU</span>
              </h3>
              <h3 className="font-titilliumWeb text-2xl mobile:text-lg">
                村松 伶士
              </h3>
            </div>
            <p className="bottom-0 font-zenOldMincho text-lg mobile:text-base">
              栃木県で生まれ育ち、現在は栃木県を拠点に映像クリエイターとして活動。
              <br />
              制作する作品は映画の様な雰囲気と透明感のある映像が特徴。
              <br />
              企画から撮影・編集まで映像制作の全てのワークフローを管理し、高度な制作でも信頼できるクリエイターと共に制作チームを組む事でハイクオリティな制作を行う。
              <br />
              自主制作では短編映画や縦型ショートドラマの企画制作も行う。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
