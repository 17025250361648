import { clsx } from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowDropUp from "../utils/icons/ArrowDropUp.jsx";
import KeyboardArrowLeft from "../utils/icons/KeyboardArrowLeft.jsx";
import KeyboardArrowRight from "../utils/icons/KeyboardArrowRight.jsx";

export default function ThumbnailSlider(props) {
  const [size, setSize] = React.useState({ height: 0, width: 0 });
  const [isSwiping, setIsSwiping] = React.useState(false);

  React.useEffect(() => {
    const handleMouseDown = () => {
      setIsSwiping(false);
    };
    window.addEventListener("mousedown", handleMouseDown, { passive: true });
    return () => {
      window.removeEventListener("mousedown", handleMouseDown, {
        passive: true,
      });
    };
  }, [setIsSwiping]);
  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setSize({
          height: entry.contentRect.height,
          width: entry.contentRect.width,
        });
      }
    });
    if (sizeRef.current) {
      resizeObserver.observe(sizeRef.current);
    }
    return () => resizeObserver.disconnect();
  }, [setSize]);

  const sizeRef = React.useRef(null);

  const sliderRef = React.useRef(null);

  const stateScreen = (text) => {
    return [...Array(2)].map((_, index) => (
      <div key={index} className="flex aspect-video content-center bg-black">
        <div className="flex justify-center text-2xl text-white">{text}</div>
      </div>
    ));
  };
  return (
    <>
      <div
        className="-mx-1 flex justify-center overflow-x-hidden pb-[5px] pt-[27px] mobile:pb-[20px] mobile:pt-[42px]"
        ref={sizeRef}
        style={{
          backgroundColor: "#252525",
        }}
      >
        <button
          className="absolute left-[calc(24%+1px)] z-10 grid size-8 -translate-y-1/2 content-center justify-center mobile:left-0"
          onClick={() => sliderRef.current && sliderRef.current.slickPrev()}
          style={{
            marginTop: size.height / 2 - 11,
          }}
        >
          <span
            className="pointer-events-none"
            style={{
              fontSize: "46px",
              color: "#F3F3F3",
              opacity: 0.6,
            }}
          >
            <KeyboardArrowLeft />
          </span>
        </button>
        <Slider
          ref={sliderRef}
          className="h-full w-[150%] mobile:w-full"
          {...{
            swipeEvent: () => {
              setIsSwiping(true);
            },
            centerMode: size.width > 1067,
            dotsClass: "my-slick-dots",
            autoplay: true,
            autoplaySpeed: 4000,
            dots: true,
            infinite: true,
            arrows: false,
            speed: 1000,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1067,
                settings: {
                  slidesToShow: 1,
                },
              },
            ],
            appendDots: (dots) => (
              <ul
                style={{
                  position: "relative",
                  bottom: "35px",
                  gap: "5px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {dots.map((dot) => (
                  <div
                    key={dot.key}
                    className={clsx(props.videoDetails ?? "opacity-0")}
                  >
                    {dot}
                  </div>
                ))}
              </ul>
            ),
            customPaging: () => (
              <button className="grid h-4 content-center">
                <div
                  style={{
                    width: "15px",
                    height: "3px",
                    backgroundColor: "#F3F3F3",
                    borderRadius: "1.5px",
                  }}
                />
              </button>
            ),
          }}
        >
          {props.error
            ? stateScreen("エラーが発生しました")
            : props.videoDetails
              ? props.videoDetails.map((videoDetail) => (
                  <Link
                    key={Object.keys(videoDetail)}
                    className="outline-none"
                    to={
                      isSwiping ? null : `/videos/${Object.keys(videoDetail)}`
                    }
                  >
                    <img
                      alt={`https://www.youtube.com/watch?v=${Object.keys(videoDetail)}`}
                      className="w-full"
                      src={`https://img.youtube.com/vi/${Object.keys(videoDetail)}/maxresdefault.jpg`}
                    />
                  </Link>
                ))
              : stateScreen("Loading...")}
        </Slider>
        <button
          className="absolute right-[24%] z-10 grid size-8 -translate-y-1/2 content-center justify-center mobile:right-0"
          onClick={() => sliderRef.current && sliderRef.current.slickNext()}
          style={{
            marginTop: size.height / 2 - 11,
          }}
        >
          <span
            className="pointer-events-none"
            style={{
              fontSize: "46px",
              color: "#F3F3F3",
              opacity: 0.6,
            }}
          >
            <KeyboardArrowRight />
          </span>
        </button>
      </div>
      <div className="text-light z-10 -mt-9 mb-3 hidden justify-items-center leading-3 mobile:grid">
        <div className="grid h-3 content-center">
          <ArrowDropUp />
        </div>
        <div className="font-zenOldMincho">check</div>
      </div>
    </>
  );
}
