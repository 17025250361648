import clsx from "clsx";
import React from "react";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import Button from "./Button";
import Altput from "../utils/logos/Altput";
import Instagram from "../utils/logos/Instagram";
import X from "../utils/logos/X";
import Youtube from "../utils/logos/Youtube";

export default function Menu() {
  const [showPullUp, setShowPullUp] = React.useState(false);
  const returnTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const sectionItems = () => {
    const menuItems = [
      { title: "Home", subtitle: "ホーム" },
      { title: "Service", subtitle: "サービス" },
      { title: "About", subtitle: "Reiji紹介" },
      { title: "Contact", subtitle: "お問い合わせ" },
    ];
    return menuItems.map((item, index) => (
      <HashLink
        to={item.title === "Home" ? "/" : `/#${item.title.toLowerCase()}`}
        onClick={item.title === "Home" ? returnTop : null}
        key={index}
        className={clsx(
          "grid size-fit w-20 items-center justify-items-center font-inter"
        )}
      >
        <div className="text-lg">{item.title}</div>
        <div className="text-sm">{item.subtitle}</div>
      </HashLink>
    ));
  };

  const navigate = useNavigate();
  return (
    <div
      // 344px はwindowの最小値である 368px - 24px
      className={clsx(
        "moblie:px-0 fixed bottom-0 z-50 mx-2 grid h-[100px] grid-flow-row gap-0 overflow-x-auto overflow-y-hidden rounded-[16px] bg-[#FFFFFF] px-[7%] transition-[height] duration-300 mobile:px-0",
        showPullUp ? "ease-out mobile:h-[360px]" : "ease-in mobile:h-[80px]"
      )}
      style={{
        width: "calc(100% - 24px)",
      }}
    >
      <div className="relative hidden h-0 mobile:grid">
        <div
          className={clsx(
            "absolute mt-6 grid h-64 w-full grid-flow-row content-center justify-items-center gap-4 transition-opacity mobile:grid",
            showPullUp
              ? "opacity-100 delay-200 duration-300 ease-in"
              : "pointer-events-none opacity-0 duration-200 ease-out"
          )}
        >
          {sectionItems()}
        </div>
        {/* <div
          className={clsx(
            "absolute mt-6 grid h-64 w-full grid-flow-row content-center justify-items-center gap-4 transition-opacity mobile:grid",
            showPullUp
              ? "opacity-100 delay-200 duration-300 ease-in"
              : "pointer-events-none opacity-0 duration-200 ease-out"
          )}
        >
          {sectionItems()}
        </div> */}

        <button
          className="relative z-30 grid h-5 w-1/3 items-center justify-items-center justify-self-center"
          onClick={() => setShowPullUp((prev) => !prev)}
        >
          <div className="absolute h-1 w-10 rounded-[2px] bg-[#DFDFDF]" />
        </button>
      </div>
      <div className="grid grid-flow-col justify-between mobile:self-end mobile:px-[3%]">
        <div className="z-20 grid w-44 items-center justify-center mobile:w-24">
          <Button
            onClick={
              window.location.pathname === "/"
                ? returnTop
                : () => {
                    navigate("/");
                    returnTop();
                  }
            }
            className="grid h-min w-[85px]"
          >
            <Altput />
          </Button>
        </div>
        <div className="grid w-min grid-flow-col content-center justify-items-center gap-12 mobile:hidden">
          {sectionItems()}
        </div>
        <div className="mx-1.5 grid w-44 grid-flow-col items-center justify-items-center gap-3 mobile:z-20 mobile:max-w-44 mobile:gap-[15px]">
          <Button
            className="size-min rounded-full"
            onClick={() =>
              window.open(
                "https://www.instagram.com/altput.reiji/reels/",
                "_blank"
              )
            }
          >
            <Instagram />
          </Button>
          <Button
            className="size-min rounded-full"
            onClick={() =>
              window.open(
                "https://www.youtube.com/channel/UCrepk_or9jwzVRU3QEp6Szw",
                "_blank"
              )
            }
          >
            <Youtube />
          </Button>
          <Button
            className="size-min rounded-full"
            onClick={() => window.open("https://twitter.com/altput", "_blank")}
          >
            <X />
          </Button>
        </div>
      </div>
    </div>
  );
}
