export default function Youtube() {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="19" cy="19" r="19" fill="#404040" />
      <rect width="28" height="20" transform="translate(5 9)" fill="#404040" />
      <path
        d="M30.8249 13.1496C30.5408 12.1032 29.7069 11.2809 28.6458 11.0008C26.7243 10.4926 19.0162 10.4926 19.0162 10.4926C19.0162 10.4926 11.3081 10.4926 9.38663 11.0008C8.32547 11.2809 7.49156 12.1032 7.2075 13.1496C6.69214 15.0444 6.69214 19 6.69214 19C6.69214 19 6.69214 22.9555 7.2075 24.8503C7.49156 25.8967 8.32547 26.719 9.38663 26.9992C11.3081 27.5074 19.0162 27.5074 19.0162 27.5074C19.0162 27.5074 26.7243 27.5074 28.6458 26.9992C29.7069 26.719 30.5408 25.8967 30.8249 24.8503C31.3403 22.9555 31.3403 19 31.3403 19C31.3403 19 31.3403 15.0444 30.8249 13.1496ZM16.551 22.6454V15.3545L22.9545 19L16.551 22.6454Z"
        fill="white"
      />
    </svg>
  );
}
