export default function Timeline() {
  return (
    <>
      <div className="block mobile:hidden">
        <svg
          width="100%"
          height="36"
          viewBox="0 0 1367 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="121" y="17" width="202" height="2" fill="#F3F3F3" />
          <rect x="352" y="17" width="202" height="2" fill="#F3F3F3" />
          <rect x="583" y="17" width="201" height="2" fill="#F3F3F3" />
          <rect x="813" y="17" width="202" height="2" fill="#F3F3F3" />
          <rect x="1044" y="17" width="202" height="2" fill="#F3F3F3" />
          <circle cx="106" cy="18" r="16" stroke="#F3F3F3" strokeWidth="4" />
          <circle cx="337" cy="18" r="16" stroke="#F3F3F3" strokeWidth="4" />
          <circle cx="568" cy="18" r="16" stroke="#F3F3F3" strokeWidth="4" />
          <circle cx="799" cy="18" r="16" stroke="#F3F3F3" strokeWidth="4" />
          <circle cx="1030" cy="18" r="16" stroke="#F3F3F3" strokeWidth="4" />
          <circle cx="1261" cy="18" r="16" stroke="#F3F3F3" strokeWidth="4" />
        </svg>
      </div>
      <div className="mx-8 hidden pl-[20%] mobile:block">
        <svg
          width="36"
          height="622"
          viewBox="0 0 36 622"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="17"
            y="600"
            width="92"
            height="2.00001"
            transform="rotate(-90 17 600)"
            fill="#F3F3F3"
          />
          <rect
            x="17"
            y="487"
            width="93"
            height="2.00001"
            transform="rotate(-90 17 487)"
            fill="#F3F3F3"
          />
          <rect
            x="17"
            y="373"
            width="92"
            height="2.00001"
            transform="rotate(-90 17 373)"
            fill="#F3F3F3"
          />
          <rect
            x="17"
            y="260"
            width="93"
            height="2.00001"
            transform="rotate(-90 17 260)"
            fill="#F3F3F3"
          />
          <rect
            x="17"
            y="146"
            width="91"
            height="2.00001"
            transform="rotate(-90 17 146)"
            fill="#F3F3F3"
          />
          <path
            d="M18 600C24.1157 600 29 604.741 29 610.5C29 616.259 24.1157 621 18 621C11.8843 621 7 616.259 7 610.5C7 604.741 11.8843 600 18 600Z"
            stroke="#F3F3F3"
            strokeWidth="2"
          />
          <path
            d="M18 487C24.1157 487 29 491.741 29 497.5C29 503.259 24.1157 508 18 508C11.8843 508 7 503.259 7 497.5C7 491.741 11.8843 487 18 487Z"
            stroke="#F3F3F3"
            strokeWidth="2"
          />
          <path
            d="M18 373C24.0751 373 29 377.925 29 384C29 390.075 24.0751 395 18 395C11.9249 395 7 390.075 7 384C7 377.925 11.9249 373 18 373Z"
            stroke="#F3F3F3"
            strokeWidth="2"
          />
          <path
            d="M18 259C24.0751 259 29 263.925 29 270C29 276.075 24.0751 281 18 281C11.9249 281 7 276.075 7 270C7 263.925 11.9249 259 18 259Z"
            stroke="#F3F3F3"
            strokeWidth="2"
          />
          <path
            d="M18 146C24.1157 146 29 150.741 29 156.5C29 162.259 24.1157 167 18 167C11.8843 167 7 162.259 7 156.5C7 150.741 11.8843 146 18 146Z"
            stroke="#F3F3F3"
            strokeWidth="2"
          />
          <path
            d="M18 33C24.1157 33 29 37.7407 29 43.5C29 49.2593 24.1157 54 18 54C11.8843 54 7 49.2593 7 43.5C7 37.7407 11.8843 33 18 33Z"
            stroke="#F3F3F3"
            strokeWidth="2"
          />
        </svg>
      </div>
    </>
  );
}
