import React from "react";

export default function Contact() {
  const heightRef = React.useRef();
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    if (process.env.NODE_ENV === "production" && heightRef.current) {
      const handleLoad = () => {
        setHeight(
          heightRef.current.contentWindow.document.body.scrollHeight + 20
        );
      };

      const iframe = heightRef.current;
      iframe.addEventListener("load", handleLoad);

      return () => {
        iframe.removeEventListener("load", handleLoad);
      };
    }
  }, [setHeight]);

  return (
    <div className="grid justify-items-center py-16">
      <h3 className="mb-6 font-inter text-2xl mobile:mb-8">Contact</h3>
      <div className="w-full">
        <p className="text-center font-inter text-base mobile:text-sm">
          プロジェクトやお見積りのご相談は
          <br />
          下記お問い合わせフォームよりご連絡ください
        </p>
        <iframe
          className="iframe-contact mx-auto max-w-[1079px] overflow-hidden"
          ref={heightRef}
          width="100%"
          height={process.env.NODE_ENV === "production" ? height : 1000}
          src="https://altput-video.com/script/mailform/contact/"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  );
}
