export default async function getVideoDetails({ setVideoDetails, setError }) {
  await fetch("https://altput-video.com/get_title/index.cgi")
    .then(async (res) => {
      const videoDetails = await res.json();
      setVideoDetails(videoDetails);
    })
    .catch((err) => {
      setError("エラーが発生しました" + err.message);
    });
}
