import clsx from "clsx";

export default function Button({ children, className, onClick }) {
  return (
    <button
      onClick={onClick}
      className={clsx("grid justify-center justify-items-center", className)}
    >
      <div className="pointer-events-none">{children}</div>
    </button>
  );
}
