export default function Altput() {
  return (
    <svg
      width="101"
      height="66"
      viewBox="0 0 101 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.0049 15.7896L26.4776 9.74559C25.1079 7.39892 21.7244 7.35277 20.2758 9.60741C20.2467 9.65243 20.2185 9.69858 20.1911 9.74559L16.6639 15.7896C15.4851 17.8092 16.544 20.2547 18.5821 20.9698C18.7618 21.037 18.9562 21.0738 19.159 21.0738C19.8525 21.0741 20.4451 20.6501 20.6853 20.0436C21.1285 19.0302 22.1483 18.3214 23.3357 18.3214C24.523 18.3214 25.5379 19.0271 25.9826 20.0365C26.2176 20.6482 26.8157 21.0829 27.5164 21.0829C27.7957 21.0829 28.059 21.0137 28.2891 20.8917C30.1935 20.0906 31.1442 17.7411 30.0049 15.7896ZM23.3342 15.4164C22.5034 15.4164 21.8298 14.75 21.8298 13.9281C21.8298 13.1062 22.5034 12.4398 23.3342 12.4398C24.1651 12.4398 24.8387 13.1062 24.8387 13.9281C24.8387 14.75 24.1651 15.4164 23.3342 15.4164Z"
        fill="#252525"
      />
      <path
        d="M41.4904 19.8573H39.071L38.4824 17.4896H35.3566L34.7812 19.8573H32.5447L35.1472 9.76587H38.8746L41.4904 19.8573ZM37.253 12.4829C37.1352 12.0302 37.0307 11.5644 36.9653 11.0986H36.8999C36.8216 11.5644 36.7167 12.0302 36.5992 12.4829L35.8015 15.6785H38.038L37.2533 12.4829H37.253Z"
        fill="#252525"
      />
      <path
        d="M48.9842 18.046V19.8573H42.6674V9.76587H45.0868V18.046H48.9842Z"
        fill="#252525"
      />
      <path
        d="M57.3543 11.6419H54.6077V19.8573H52.2537V11.6419H49.5072V9.76587H57.3543V11.6419Z"
        fill="#252525"
      />
      <path
        d="M62.0099 9.76587C64.4293 9.76587 65.7373 10.8268 65.7373 13.0909C65.7373 15.5748 64.0894 16.6617 61.7616 16.6617H60.9507V19.8573H58.662V9.76587H62.0102H62.0099ZM61.8921 14.9797C62.729 14.9797 63.3176 14.462 63.3176 13.2202C63.3176 11.9783 62.729 11.4476 61.8921 11.4476H60.9504V14.9797H61.8921Z"
        fill="#252525"
      />
      <path
        d="M75.4148 9.76587V16.2346C75.4148 18.8351 73.7146 19.9867 71.2952 19.9867C68.8758 19.9867 67.1755 18.8351 67.1755 16.2346V9.76587H69.5949V16.1312C69.5949 17.658 70.301 18.2919 71.2952 18.2919C72.2893 18.2919 72.9955 17.658 72.9955 16.1312V9.76587H75.4148Z"
        fill="#252525"
      />
      <path
        d="M84.5697 11.6419H81.8232V19.8573H79.4691V11.6419H76.7226V9.76587H84.5697V11.6419Z"
        fill="#252525"
      />
      <path
        d="M30.5967 41.5439L35.174 51.385H29.2311L24.9959 41.7525"
        fill="#252525"
      />
      <path
        d="M33.9665 31.1377C33.2995 29.9893 32.2938 29.0853 30.9492 28.4255C29.6043 27.7657 27.9777 27.4358 26.0693 27.4358H16.172V51.385H22.0688V42.3445H23.4828C24.6973 42.3354 28.5851 42.2103 29.5867 41.9012C30.5636 41.5995 32.3684 40.9152 33.6039 39.24C34.1352 38.5195 34.4252 37.7899 34.4964 37.6039C34.8097 36.7868 34.9664 35.9105 34.9664 34.9758C34.9664 33.5658 34.6329 32.2861 33.9659 31.138L33.9665 31.1377ZM28.1554 37.4318C27.6149 37.9549 26.8045 38.2167 25.7242 38.2167H22.0688V32.2804H25.7242C26.8045 32.2804 27.6149 32.5419 28.1554 33.0653C28.696 33.5886 28.9661 34.3048 28.9661 35.2148C28.9661 36.1698 28.696 36.9091 28.1554 37.4321V37.4318Z"
        fill="#252525"
      />
      <path
        d="M55.1987 42.9764H42.8408C42.9261 44.0713 43.2829 44.9083 43.9107 45.4875C44.5385 46.0667 45.3103 46.3561 46.2258 46.3561C47.588 46.3561 48.5355 45.7877 49.0677 44.6505H54.8796C54.5815 45.8088 54.0438 46.8513 53.2668 47.7777C52.4899 48.7045 51.5159 49.4307 50.3452 49.9572C49.1742 50.4836 47.8651 50.7469 46.4176 50.7469C44.6718 50.7469 43.1176 50.3782 41.7554 49.6412C40.3929 48.9042 39.3285 47.8512 38.5622 46.4823C37.7956 45.1134 37.4125 43.5132 37.4125 41.681C37.4125 39.8489 37.7901 38.2483 38.546 36.8794C39.302 35.5105 40.3609 34.4576 41.7234 33.7205C43.0859 32.9835 44.6505 32.6149 46.4176 32.6149C48.1847 32.6149 49.6745 32.973 51.0156 33.6892C52.3568 34.4054 53.4051 35.4265 54.161 36.7529C54.9164 38.0797 55.2946 39.6275 55.2946 41.3967C55.2946 41.9021 55.2626 42.4286 55.199 42.9761L55.1987 42.9764ZM49.7062 39.9756C49.7062 39.0489 49.3868 38.3119 48.7483 37.7643C48.1098 37.2167 47.3113 36.943 46.3534 36.943C45.3956 36.943 44.6661 37.2062 44.0383 37.7327C43.4105 38.2592 43.0217 39.007 42.8728 39.9756H49.7062Z"
        fill="#252525"
      />
      <path
        d="M64.2236 32.3484H58.3268V51.3849H64.2236V32.3484Z"
        fill="#252525"
      />
      <path
        d="M66.7064 55.513C67.35 55.513 67.8099 55.3877 68.0858 55.1378C68.362 54.8874 68.5 54.478 68.5 53.9097V32.3484H74.3968V53.8413C74.3968 56.1837 73.799 57.8725 72.6035 58.9072C71.4078 59.942 69.3585 60.4645 66.7061 60.4596"
        fill="#252525"
      />
      <path
        d="M84.5357 32.3484H78.6389V51.3849H84.5357V32.3484Z"
        fill="#252525"
      />
      <path
        d="M61.2752 30.1308C62.9036 30.1308 64.2236 28.825 64.2236 27.2141C64.2236 25.6032 62.9036 24.2974 61.2752 24.2974C59.6468 24.2974 58.3268 25.6032 58.3268 27.2141C58.3268 28.825 59.6468 30.1308 61.2752 30.1308Z"
        fill="#252525"
      />
      <path
        d="M71.4487 30.1308C73.077 30.1308 74.3971 28.825 74.3971 27.2141C74.3971 25.6032 73.077 24.2974 71.4487 24.2974C69.8203 24.2974 68.5003 25.6032 68.5003 27.2141C68.5003 28.825 69.8203 30.1308 71.4487 30.1308Z"
        fill="#252525"
      />
      <path
        d="M81.5873 30.1308C83.2157 30.1308 84.5357 28.825 84.5357 27.2141C84.5357 25.6032 83.2157 24.2974 81.5873 24.2974C79.9589 24.2974 78.6389 25.6032 78.6389 27.2141C78.6389 28.825 79.9589 30.1308 81.5873 30.1308Z"
        fill="#252525"
      />
    </svg>
  );
}
